<template>
    <time-report-details
        :readOnly="false"
        :pageSpecificDetails="pageSpecificDetails"
        :receivedYearMonth="yearMonth"
        :userGid="userGid"
        :receivedSaveActualTimeReport.sync="saveActualTimeReport"
    />
</template>

<script>
import moment from 'moment';
import colors from '@/styles/colors.scss';
import dateFormatConstants from '@/constants/date-format.constants';
import { USER_ROLES } from '@/constants/utils.constants';
import TimeReportDetails from '@/components/time-report-details';

export default {
    name: 'executive-page',

    components: {
        TimeReportDetails,
    },

    data() {
        return {
            yearMonth: undefined,
            saveActualTimeReport: false,
            pageSpecificDetails: {
                backgroundColor: {
                    default: 'accent',
                    validated: 'info',
                },
                role: USER_ROLES.EXECUTIVE,
            },
        };
    },

    computed: {
        userGid() {
            return this.$store.state.userGid;
        },
    },

    async created() {
        this.loadExecutiveTheme();

        this.yearMonth =
            this.$route.query['year-month'] ||
            moment().format(dateFormatConstants.YEAR_MONTH_FORMAT);

        if (this.$route.query && this.$route.query['year-month'] && this.$route.query.validate) {
            this.saveActualTimeReport = true;
            this.$router.replace({ query: null });
        }
    },

    methods: {
        loadExecutiveTheme() {
            const theme = {
                primary: colors.primary,
                secondary: colors.secondary,
                accent: colors.accent,
            };
            this.$emit('change-theme', theme);
        },
    },
};
</script>
